#wrapper::-webkit-scrollbar {
  width: 6px;
}

#wrapper::-webkit-scrollbar-track {
  background-color: white;
}

#wrapper::-webkit-scrollbar-thumb {
  background-color: orange;
}

// body {
//   margin: 0;
//   font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
//   font-weight: 400;
//   line-height: 1.5;
//   color: #858796;
//   text-align: left;
//   background-color: #fff;
// }

.img-template-toc {
  opacity: 0.8;
  min-height: 80px;
  width: 100vw;
}

.logo-icon-toc {
  position: absolute;
  width: 250px;
  height: 150px;
  top: 70%;
  left: 47%;
  margin-top: -40px;
  margin-left: -40px;
}

.text-toc {
  margin-bottom: 1rem;
}

@media (min-width: 376px) {
  .logo-icon-toc {
      width: 190px;
      height: 100px;
      top: 100%;
      left: 45%;
      margin-top: -50px;
      margin-left: -50px;
  }
}

@media (min-width: 576px) {
  .logo-icon-toc {
      width: 250px;
      height: 150px;
      top: 70%;
      left: 47%;
      margin-top: -50px;
      margin-left: -50px;
  }
}

@media (min-width: 768px) {
  .logo-icon-toc {
      width: 250px;
      height: 150px;
      top: 70%;
      left: 47%;
      margin-top: -40px;
      margin-left: -40px;
  }
}

#wrapper-toc {
  display: flex;
  margin: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  line-height: 1.5;
  font-size: 1.2rem;
  color: #858796;
  text-align: left;
  background-color: #fff;
}

#wrapper-toc #content-wrapper-toc {
  background-color: #f8f9fc;
  width: 100%;
  overflow-x: hidden;
}

#wrapper-toc #content-wrapper-toc #content-toc {
  flex: 1 0 auto;
}

.container-fluid-toc {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 100px;
  /* overflow: auto; */
}

.align-items-center-toc {
  align-items: center !important;
}

.row-toc {
  display: flex;
  flex-wrap: wrap;
  // margin-right: -0.75rem;
  // margin-left: -0.75rem;
}

.flex-column-toc {
  flex-direction: column !important;
}

.d-flex-toc {
  display: flex !important;
}