.dataTables_filter {
  display: none;
}

.dataTables_length {
  display: none;
}

#kt_content {
  background-color: #f5f8fa;
}

.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

ul.custom-bullet {
}

.custom-bullet li {
  position: relative;
  list-style-type: none;
}

.custom-bullet li::before {
  content: '•';
  position: absolute;
  left: -1em;
  font-size: 1.5em;
  line-height: 0.9em;
  padding: 0px;
}

.react-international-phone-input-container{
  width: 100%;
  
  .react-international-phone-input  {
    width: 40%;
  }
}