.chat {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 15px;
  padding: 8px;
  position: relative;
  margin: 1px 0;
  max-width: 85%;
  word-wrap: break-word;
  z-index: -1;
}

.chat:after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
}

.metadata {
  /* display: inline-block; */
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -4px;
}

.metadata .time {
  color: rgba(0, 0, 0, 0.45);
  font-size: 11px;
  display: inline-block;
}

.chat.received {
  background: #fff;
  border-radius: 0px 5px 5px 5px;
  float: left;
}

.chat.received .metadata {
  padding: 0 0 0 16px;
}

.chat.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  left: -10px;
}

.chat.received2 {
  background: #fff;
  border-radius: 5px 5px 5px 5px;
  float: left;
}

.chat.received2 .metadata {
  padding: 0 0 0 16px;
}

.chat.received2:after {
  border-style: none;
}

.chat.received2.empty {
  background: #fff0;
}

.chat.sent {
  background: #dcf8c6; /* A light green background for sent messages */
  border-radius: 5px 0px 5px 5px;
  float: right;
}

.chat.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #dcf8c6; /* same color as .chat.sent background */
  top: 0;
  right: -10px;
  left: auto; /* Override the left set by .chat.received:after */
}

.chat.sent2 {
  background: #dcf8c6; /* A light green background for sent messages */
  border-radius: 5px 5px 5px 5px;
  float: right;
}

.chat.sent2:after {
  border-style: none;
}

.attachment-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px !important;
}

.logs .rdt_TableRow:hover {
  background-color: $primary-light !important;
  cursor: pointer;
}
